import PflagLogo from "../../images/pflag/PFLAGLogo.png"
import Pflag1 from "../../images/pflag/Pflag1.png"
import Pflag2 from "../../images/pflag/Pflag2.png"
import Pflag4 from "../../images/pflag/Pflag4.png"
import Pflag5 from "../../images/pflag/Pflag5.png"


function Pflag() {
    return (
        <>
        <div className="flex max-h-[92vh]">
            <div className="flex flex-col place-content-center bg-jade h-[86vh] w-[33vw] mt-[3vh] mb-[3vh] ml-[3vh] rounded-lg">
                <div className="flex-col pb-4">
                    <p className="flex text-2xl mx-8 font-bold italic">PFLAG Identity:</p>
                    <p className="flex text-xl mx-8 font-bold">LGBTQ+ Nonprofit Organization Logo and <br />Stationary Redesign</p>
                </div>
                <p className="align-middle text-lg px-8 pb-4 ">PFLAG is the first and largest organization dedicated to helping create an equitable and inclusive world where every LGBTQ+ person is safe, celebrated, empowered, and loved. The new logo encompasses their ideals of love and unity by using the triangle and heart symbols of their past logo design. The triangle represents the people of the LGBTQ+ community and the heart represents the friends, family and allies of the community. The rounded simplified typeface and design creates a softer welcoming symbol to represent their organization, while keeping a main structure of what the logo represented before. The rainbow flag is also a key symbol that represents the community so the addition of those color variations is to represent that.</p>
                <p className="align-middle text-lg px-8">The business cards and letterhead highlight the new rainbow color pallet. The varied color business cards allow for more variety and interest with a possibility of different titles having different colors, while keeping them unified together. The bright pastel colors bring a warm inviting sense to the stationery to convey their mission of creating a more caring world.</p>
            </div>
            <div className="flex flex-col place-items-center h-92[vh] w-[67vw] overflow-y-scroll scrollbar-thin scrollbar-thumb-jade scrollbar-rounded-lg">
                <label htmlFor="my-modal-1" className=""><img alt="" src={PflagLogo} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw]"/></label>
                <label htmlFor="my-modal-5" className=""><img alt="" src={Pflag4} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[40vw]"/></label>
                <label htmlFor="my-modal-3" className=""><img alt="" src={Pflag2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw]"/></label>
                <label htmlFor="my-modal-6" className=""><img alt="" src={Pflag5} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[40vw]"/></label>
                <label htmlFor="my-modal-2" className=""><img alt="" src={Pflag1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[45vw]"/></label>
            </div>
        </div>
        <input type="checkbox" id="my-modal-1" className="modal-toggle" />
        <label htmlFor="my-modal-1" className="modal cursor-pointer">
            <div className="modal-box w-11/12 max-w-[95rem]">
                <img alt="" src={PflagLogo} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[91.8rem]"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-2" className="modal-toggle" />
        <label htmlFor="my-modal-2" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[60rem] max-h-[60rem]">
                <img alt="" src={Pflag1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[57rem] h-[57rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-3" className="modal-toggle" />
        <label htmlFor="my-modal-3" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[78rem] max-h-[60rem]">
                <img alt="" src={Pflag2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
        <label htmlFor="my-modal-5" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[64rem] max-h-[60rem]">
                <img alt="" src={Pflag4} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[61.2rem] h-[57rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-6" className="modal-toggle" />
        <label htmlFor="my-modal-6" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[54rem] max-h-[59rem]">
                <img alt="" src={Pflag5} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[51rem] h-[55.8rem] object-cover"/>
            </div>
        </label>
        </>
        )
}
export default Pflag;
