import { NavLink } from "react-router-dom";
import WhiteBoop from "../images/BoopWhite.png"

function NavBar() {
    return (
        <div className="flex justify-between bg-jade text-white h-[8vh] content-center">
            <div className="pl-4 text-xl flex place-items-center">
                <NavLink to="/" className="text-white text-2xl hover:scale-105">Jade Love Bott</NavLink>
                <img alt="" src={WhiteBoop} className="h-[3rem] mb-2" />
            </div>
            <div className="flex justify-evenly text-xl pr-4 content-center">
                <div className="flex place-items-center">
                    <NavLink to="about" className="p-2 text-white text-xl hover:scale-105">
                        About
                    </NavLink>
                </div>
                <div className="flex place-items-center">
                    <NavLink to="work" className="p-2 text-white text-xl hover:scale-105">
                        Work
                    </NavLink>
                </div>
                <div className="flex place-items-center">
                    <NavLink to="contact" className="p-2 text-white text-xl hover:scale-105">
                        Contact
                    </NavLink>
                </div>
            </div>
        </div>
    )

}
export default NavBar;
