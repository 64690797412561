import T1 from "../../images/typography/Type101MockUp.png"
import T2 from "../../images/typography/Typography.jpeg"
import T3 from "../../images/typography/T101p2.jpeg"
import T4 from "../../images/typography/T101p3.jpeg"
import T5 from "../../images/typography/T101p4.jpeg"
import T6 from "../../images/typography/T101p5.jpeg"
import T7 from "../../images/typography/T101p6.jpeg"
import T8 from "../../images/typography/T101p7.jpeg"
import T9 from "../../images/typography/T101p8.jpeg"
import T10 from "../../images/typography/T101p9.jpeg"
import T11 from "../../images/typography/T101p10.jpeg"

function Type() {
    return (
        <>
        <div className="flex max-h-[92vh]">
            <div className="flex flex-col place-content-center bg-jade h-[86vh] w-[33vw] mt-[3vh] mb-[3vh] ml-[3vh] rounded-lg">
                <div className="flex-col pb-16">
                    <p className="flex text-2xl font-bold italic mx-8">Type 101: Know The Rules To <br /> Break The Rules:</p>
                    <p className="flex text-xl font-bold mx-8">Booklet design of typography rules</p>
                </div>
                <p className="text-xl px-8">This is a collaborative booklet that defines the rules of typography. The punk/feminist zine style is conveyed with the DIY hand-made elements. Each team member was able to show their creative voice designing their section, and still worked toward cohesiveness. The title “Know the Rules to Break the Rules” was emphasized by having the definitions not follow the rules, but while also having the correct way highlighted inorder to effectively communicate the idea.</p>
            </div>
            <div className="flex flex-col place-items-center h-92[vh] w-[67vw] overflow-y-scroll scrollbar-thin scrollbar-thumb-jade scrollbar-rounded-lg">
                <label htmlFor="my-modal-1" className=""><img alt="" src={T1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[45vw]"/></label>
                <label htmlFor="my-modal-2" className=""><img alt="" src={T2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[28vw]"/></label>
                <div className="flex place-items-between">
                    <label htmlFor="my-modal-3" className=""><img alt="" src={T3} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[23vw]"/></label>
                    <label htmlFor="my-modal-4" className=""><img alt="" src={T4} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[23vw]"/></label>
                </div>
                <div className="flex place-items-between">
                    <label htmlFor="my-modal-5" className=""><img alt="" src={T5} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[23vw]"/></label>
                    <label htmlFor="my-modal-6" className=""><img alt="" src={T6} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[23vw]"/></label>
                </div>
                <div className="flex place-items-between">
                    <label htmlFor="my-modal-7" className=""><img alt="" src={T7} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[23vw]"/></label>
                    <label htmlFor="my-modal-8" className=""><img alt="" src={T8} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[23vw]"/></label>
                </div>
                <div className="flex place-items-between">
                    <label htmlFor="my-modal-9" className=""><img alt="" src={T9} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[23vw]"/></label>
                    <label htmlFor="my-modal-10" className=""><img alt="" src={T10} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[23vw]"/></label>
                </div>
                <label htmlFor="my-modal-11" className=""><img alt="" src={T11} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[28vw]"/></label>
            </div>
        </div>
        <input type="checkbox" id="my-modal-1" className="modal-toggle" />
        <label htmlFor="my-modal-1" className="modal cursor-pointer">
            <div className="modal-box w-11/12 max-w-[57rem]">
                <img alt="" src={T1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[53.75rem] h-[51.8rem]"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-2" className="modal-toggle" />
        <label htmlFor="my-modal-2" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[41rem]">
                <img alt="" src={T2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[38rem] h-[50.6rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-3" className="modal-toggle" />
        <label htmlFor="my-modal-3" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[75rem]">
                <img alt="" src={T3} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[72.6rem] h-[50.6rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
        <label htmlFor="my-modal-4" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[75rem]">
                <img alt="" src={T4} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[72.6rem] h-[50.6rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
        <label htmlFor="my-modal-5" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[75rem]">
                <img alt="" src={T5} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[72.6rem] h-[50.6rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-6" className="modal-toggle" />
        <label htmlFor="my-modal-6" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[75rem]">
                <img alt="" src={T6} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[72.6rem] h-[50.6rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-7" className="modal-toggle" />
        <label htmlFor="my-modal-7" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[75rem]">
                <img alt="" src={T7} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[72.6rem] h-[50.6rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-8" className="modal-toggle" />
        <label htmlFor="my-modal-8" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[75rem]">
                <img alt="" src={T8} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[72.6rem] h-[50.6rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-9" className="modal-toggle" />
        <label htmlFor="my-modal-9" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[75rem]">
                <img alt="" src={T9} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[72.6rem] h-[50.6rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-10" className="modal-toggle" />
        <label htmlFor="my-modal-10" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[75rem]">
                <img alt="" src={T10} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[72.6rem] h-[50.6rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-11" className="modal-toggle" />
        <label htmlFor="my-modal-11" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[41rem]">
                <img alt="" src={T11} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[38rem] h-[50.6rem] object-cover"/>
            </div>
        </label>
        </>
        )
}
export default Type;
