import { useState } from "react"
import { useNavigate } from "react-router-dom"
import KeroTreeOutside from "../images/sculpture/KeroTreeOutside.png"
import Typography from "../images/typography/Typography.jpeg"
import PlasticTitanic from "../images/titanic/PlasticTitanic.png"
import PFLAGLogo from "../images/pflag/PFLAGLogo.png"
import LukovaBrochure from "../images/lukova/LukovaBrochure.png"
import YPLogo from "../images/young/YPLogoFull.png"
import SockLogo from "../images/sock/SockLogo.png"

function Gallery() {

    const navigate = useNavigate();
    const [typography, setTypography] = useState(false)
    const [sculpture, setSculpture] = useState(false);
    const [titanic, setTitanic] = useState(false);
    const [pflag, setPflag] = useState(false);
    const [lukova, setLukova] = useState(false);
    const [yp, setYp] = useState(false);
    const [sock, setSock] = useState(false);


    return (
        <div className="grid grid-rows-auto grid-cols-2 grid-flow-row px-60 py-4 max-h-[92vh] overflow-y-scroll scrollbar-none">
            <div onClick={() => navigate("/work/plastic-titanic")} className="m-4 justify-self-end" >
                <div onMouseEnter={() => setTitanic(true)} onMouseLeave={() => setTitanic(false)} className="relative w-[31.25rem] h-[46.9rem] hover:scale-105 hover:border-jade">
                    <img alt="" src={PlasticTitanic} className="w-[31.25rem] h-[46.9rem] object-cover border-2 border-jade rounded-lg shadow-2xl shadow-black-600"/>
                    {titanic === true ? (
                        <div className="absolute flex place-items-center place-content-center top-0 left-0 w-[31.25rem] h-[46.9rem] bg-jade bg-opacity-[85%] text-lg font-bold rounded-lg">
                            <div className="flex-col">
                                <p className="flex justify-center text-center">Plastic Titanic Poster:</p>
                                <p className="flex justify-center text-center">Public service announcement poster design on the effects of climate change</p>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div onClick={() => navigate("/work/type-101")} className="m-4 justify-self-start self-end" >
                <div onMouseEnter={() => setTypography(true)} onMouseLeave={() => setTypography(false)} className="relative w-[30rem] h-[39.3rem] hover:scale-105 hover:border-jade">
                    <img alt="" src={Typography} className="w-[30rem] h-[39.3rem] object-cover border-2 border-jade rounded-lg shadow-2xl shadow-black-600"/>
                    {typography === true ? (
                        <div className="absolute flex place-items-center place-content-center top-0 left-0 w-[30rem] h-[39.3rem] bg-jade bg-opacity-[85%] text-lg font-bold rounded-lg">
                            <div className="flex-col">
                                <p className="flex justify-center text-center">Type 101: Know The Rules To Break The Rules:</p>
                                <p className="flex justify-center text-center">Booklet design of typography rules</p>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div onClick={() => navigate("/work/lukova-brochure")} className="col-span-2 m-4 place-self-center" >
                <div onMouseEnter={() => setLukova(true)} onMouseLeave={() => setLukova(false)} className="relative w-[68rem] h-[30.8rem] hover:scale-105 hover:border-jade">
                    <img alt="" src={LukovaBrochure} className="w-[68rem] h-[30.8rem] object-cover border-2 border-jade rounded-lg shadow-2xl shadow-black-600"/>
                    {lukova === true ? (
                        <div className="absolute flex place-items-center place-content-center top-0 left-0 w-[68rem] h-[30.8rem] bg-jade bg-opacity-[85%] text-lg font-bold rounded-lg">
                            <div className="flex-col">
                                <p className="flex justify-center text-center">Luba Lukova - Pioneers in Graphic Design:</p>
                                <p className="flex justify-center text-center">Brochure design for the AIGA.</p>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            <div onClick={() => navigate("/work/pflag-identity")} className="place-self-end m-4" >
                <div onMouseEnter={() => setPflag(true)} onMouseLeave={() => setPflag(false)} className="relative w-[50rem] h-[10rem] object-cover rounded-sm hover:scale-105 hover:border-jade">
                    <img alt="" src={PFLAGLogo} className="w-[50rem] object-cover border-2 border-jade rounded-lg shadow-2xl shadow-black-600"/>
                    {pflag === true ? (
                        <div className="absolute flex place-items-center place-content-center top-0 left-0 w-[50rem] h-[11.5rem] bg-jade bg-opacity-[85%] text-lg font-bold rounded-lg">
                            <div className="flex-col">
                                <p className="flex justify-center text-center">PFLAG Identity:</p>
                                <p className="flex justify-center text-center">LGBTQ+ Nonprofit Organization Logo and Stationary Redesign</p>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div onClick={() => navigate("/work/park-identity")} className="row-span-2 m-4" >
                <div onMouseEnter={() => setYp(true)} onMouseLeave={() => setYp(false)} className="relative w-[40rem] h-[28.5rem] object-cover rounded-sm hover:scale-105 hover:border-jade">
                    <img alt="" src={YPLogo} className=" w-[40rem] h-[28.5rem] object-cover border-2 border-jade rounded-lg shadow-2xl shadow-black-600"/>
                    {yp === true ? (
                        <div className="absolute flex place-items-center place-content-center top-0 left-0 w-[40rem] h-[28.5rem] bg-jade bg-opacity-[85%] text-lg font-bold rounded-lg">
                            <div className="flex-col">
                                <p className="flex justify-center text-center">Personal Logo:</p>
                                <p className="flex justify-center text-center">Identity Design for Youngsuk Park</p>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div onClick={() => navigate("/work/lost-soles")} className="m-4 place-self-end" >
                <div onMouseEnter={() => setSock(true)} onMouseLeave={() => setSock(false)} className="relative w-[44rem] h-[11.8rem] hover:scale-105 hover:border-jade">
                    <img alt="" src={SockLogo} className="w-[44rem] h-[11.8rem] object-cover border-2 border-jade rounded-lg shadow-2xl shadow-black-600"/>
                    {sock === true ? (
                        <div className="absolute flex place-items-center place-content-center top-0 left-0 w-[44rem] h-[11.8rem] bg-jade bg-opacity-[85%] text-lg font-bold rounded-lg">
                            <div className="flex-col">
                                <p className="flex justify-center text-center">Lost Soles:</p>
                                <p className="flex justify-center text-center">Web page design for a sock matching website</p>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            <div onClick={() => navigate("/work/sculpture")} className="col-span-2 place-self-center m-4" >
                <div onMouseEnter={() => setSculpture(true)} onMouseLeave={() => setSculpture(false)} className="relative w-[37.5rem] h-[31.5rem] object-cover rounded-sm hover:scale-105 hover:border-jade">
                    <img alt="" src={KeroTreeOutside} className="w-[37.5rem] h-[31.5rem] object-cover border-2 border-jade rounded-lg shadow-2xl shadow-black-600"/>
                    {sculpture === true ? (
                        <div className="absolute flex place-items-center place-content-center top-0 left-0 w-[37.5rem] h-[31.5rem] bg-jade bg-opacity-[85%] text-lg font-bold rounded-lg">
                            <div className="flex-col">
                                <p className="flex justify-center text-center">Sculpture</p>
                                <p className="flex justify-center text-center">Collection of 3D fine art work</p>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>

    )
}

export default Gallery;
