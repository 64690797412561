import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Boop from "../images/BoopWhite.png";

function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
        .then((result) => {
            console.log(result.text);
            e.target.reset();
        }, (error) => {
            console.log(error.text);
        });


    };

    return (
            <div className="flex flex-col rounded-lg place-items-center place-content-center h-[92vh] w-screen">
                <form ref={form} onSubmit={sendEmail} className="flex flex-col mx-16 h-[70vh] w-[40vw] bg-jade p-8 mb-20 rounded-lg place-content-around">
                    <p className="text-center text-3xl m-4 mb-1">Excited to Hear From You!</p>
                    <div className='flex place-content-center w-100'>
                        <img alt="" src={Boop} className="h-[5vh] w-[2.5vw]"/>
                    </div>
                    <div className='flex flex-col'>
                        <label className='text-2xl mb-2'>Name</label>
                        <input type="text" name="user_name" className="text-jade rounded-lg pl-2 pt-1 h-10" id="i1"/>
                    </div>
                    <div className='flex flex-col'>
                        <label className='text-2xl mb-2 mt-4'>Email</label>
                        <input type="email" name="user_email" className="text-jade rounded-lg pl-2 pt-1 h-10" id="i2" />
                    </div>
                    <div className='flex flex-col'>
                        <label className='text-2xl mb-2 mt-4'>Message</label>
                        <textarea name="message" className="text-jade rounded-lg pl-2 pt-1 h-40" id="i3" />
                    </div>
                    <input type="submit" value="Send" className="m-4 text-2xl"/>
                </form>
            </div>
    );
};

export default Contact;
