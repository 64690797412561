import S1 from "../../images/sculpture/KeroTreeOutside.png"
import S2 from "../../images/sculpture/KeroTree2.png"
import S3 from "../../images/sculpture/BookFlower.png"
import S4 from "../../images/sculpture/BookFlowerBetrayal.png"
import S5 from "../../images/sculpture/BookFlowerDeath.png"
import S6 from "../../images/sculpture/BookFlowerIncest.png"
import S7 from "../../images/sculpture/VinylRose.png"
import S8 from "../../images/sculpture/ShiningDiamondON.jpeg"
import S9 from "../../images/sculpture/ShiningDiamondOFF.jpeg"

function Sculpture() {
    return (
        <>
        <div className="flex max-h-[92vh]">
            <div className="flex flex-col place-content-center bg-jade h-[86vh] w-[33vw] mt-[3vh] mb-[3vh] ml-[3vh] rounded-lg">
                <div className="flex-col pb-16">
                    <p className="flex text-2xl font-bold italic mx-8">Sculpture:</p>
                    <p className="flex text-xl font-bold mx-8">Collection of 3D fine art work</p>
                </div>
                <p className="text-xl px-8">Exploring making and designing for 3D, in-the-round work, allows for different kinds of creativity to emerge that the 2D cannot convey. This sculpture work entails mostly simple designs communicating a prompt, but it is minimalistic enough for the viewer to take their own interpretation. Working to create a specific aesthetic, hands on, works on developing creativity and perspective.</p>
            </div>
            <div className="flex flex-col place-items-center h-92[vh] w-[67vw] overflow-y-scroll scrollbar-thin scrollbar-thumb-jade scrollbar-rounded-lg">
                <label htmlFor="my-modal-1" className=""><img alt="" src={S1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[40vw]"/></label>
                <label htmlFor="my-modal-2" className=""><img alt="" src={S2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[40vw]"/></label>
                <label htmlFor="my-modal-3" className=""><img alt="" src={S3} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[32vw]"/></label>
                <div className="flex place-items-between">
                    <label htmlFor="my-modal-4" className=""><img alt="" src={S4} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[15vw]"/></label>
                    <label htmlFor="my-modal-5" className=""><img alt="" src={S5} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[15vw]"/></label>
                    <label htmlFor="my-modal-6" className=""><img alt="" src={S6} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[15vw]"/></label>
                </div>
                <label htmlFor="my-modal-7" className=""><img alt="" src={S7} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[32vw]"/></label>
                <div className="flex place-items-between">
                <label htmlFor="my-modal-8" className=""><img alt="" src={S8} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[23vw]"/></label>
                <label htmlFor="my-modal-9" className=""><img alt="" src={S9} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[23vw]"/></label>
                </div>

            </div>
        </div>
        <input type="checkbox" id="my-modal-1" className="modal-toggle" />
        <label htmlFor="my-modal-1" className="modal cursor-pointer">
            <div className="modal-box w-11/12 max-w-[60rem]">
                <img alt="" src={S1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[57.4rem] h-[49.2rem]"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-2" className="modal-toggle" />
        <label htmlFor="my-modal-2" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[60rem]">
                <img alt="" src={S2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[57.4rem] h-[49.2rem]"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-3" className="modal-toggle" />
        <label htmlFor="my-modal-3" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[41rem]">
                <img alt="" src={S3} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[38.1rem] h-[51rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
        <label htmlFor="my-modal-4" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[39rem]">
                <img alt="" src={S4} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[35.8rem] h-[49.2rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
        <label htmlFor="my-modal-5" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[39rem]">
                <img alt="" src={S5} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[35.8rem] h-[43rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-6" className="modal-toggle" />
        <label htmlFor="my-modal-6" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[39rem]">
                <img alt="" src={S6} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[35.8rem] h-[49.2rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-7" className="modal-toggle" />
        <label htmlFor="my-modal-7" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[42rem]">
                <img alt="" src={S7} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[38.5rem] h-[51.7rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-8" className="modal-toggle" />
        <label htmlFor="my-modal-8" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[43rem]">
                <img alt="" src={S8} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[39.9rem] h-[52.92rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-9" className="modal-toggle" />
        <label htmlFor="my-modal-9" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[43rem]">
                <img alt="" src={S9} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[39.9rem] h-[52.92rem] object-cover"/>
            </div>
        </label>
        </>
        )
}
export default Sculpture;
