import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function GalleryRedirect() {
    const navigate = useNavigate()

    useEffect(() => {
        navigate("/work")
    })

}
export default GalleryRedirect;
