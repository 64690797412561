import LukovaBrochure from "../../images/lukova/LukovaBrochure.png"
import LukovaBrochure2 from "../../images/lukova/LukovaBrochure2.png"
import LukovaBrochure3 from "../../images/lukova/LukovaBrochure3.png"
import LukovaBrochure4 from "../../images/lukova/LukovaBrochure4.png"

function Lukova() {
    return (
        <>
        <div className="flex max-h-[92vh]">
            <div className="flex flex-col place-content-center bg-jade h-[86vh] w-[33vw] mt-[3vh] mb-[3vh] ml-[3vh] rounded-lg">
                <div className="flex-col pb-12">
                    <p className="flex mx-8 text-2xl font-bold italic">Luba Lukova - Pioneers in <br />Graphic Design:</p>
                    <p className="flex mx-8 text-xl font-bold">Brochure design for the AIGA</p>
                </div>
                <p className="text-xl px-8">Luba Lukova is a world renowned Graphic Designer known for a strong use of metaphor within her imagery. Her mark on the design world has made her a true Pioneer in Graphic Design and this brochure for AIGA presents Lukova as a stand out artist. Allowing the strong metaphorical imagery to be the main focus of the design emphasizes the bold messages in Lukova’s work. Each outside panel showcases some of the bright colors that are a consistent theme in Lukova’s designs; she often uses these simple color pallets to stand out. The timeline displays various commentaries from events around the world as Lukova fights to discuss various socio-political topics to provoke deeper thinking. </p>
            </div>
            <div className="flex flex-col place-items-center h-92[vh] w-[67vw] overflow-y-scroll scrollbar-thin scrollbar-thumb-jade scrollbar-rounded-lg">
                <label htmlFor="my-modal-1" className=""><img alt="" src={LukovaBrochure} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw]"/></label>
                <label htmlFor="my-modal-2" className=""><img alt="" src={LukovaBrochure2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw] object-cover "/></label>
                <label htmlFor="my-modal-3" className=""><img alt="" src={LukovaBrochure3} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw] object-cover "/></label>
                <label htmlFor="my-modal-4" className=""><img alt="" src={LukovaBrochure4} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw] object-cover "/></label>
            </div>
        </div>
        <input type="checkbox" id="my-modal-1" className="modal-toggle" />
        <label htmlFor="my-modal-1" className="modal cursor-pointer">
            <div className="modal-box w-11/12 max-w-[95rem]">
                <img alt="" src={LukovaBrochure} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[91.8rem] h-[41.6rem]"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-2" className="modal-toggle" />
        <label htmlFor="my-modal-2" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[78rem] max-h-[60rem]">
                <img alt="" src={LukovaBrochure2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-3" className="modal-toggle" />
        <label htmlFor="my-modal-3" className="modal cursor-pointer">
            <div className="modal-box w-11/12 max-w-[80rem]">
                <img alt="" src={LukovaBrochure3} className="border-2 border-jade rounded-lg shadow-2xl shadow-black h-[50rem]"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
        <label htmlFor="my-modal-4" className="modal cursor-pointer">
            <div className="modal-box w-11/12 max-w-[80rem]">
                <img alt="" src={LukovaBrochure4} className="border-2 border-jade rounded-lg shadow-2xl shadow-black h-[50rem]"/>
            </div>
        </label>
        </>

    )
}
export default Lukova;
