import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import GalleryRedirect from "./components/GalleryRedirect";
import Gallery from "./components/Gallery";
import About from "./components/About";
import LostSoles from "./components/work/LostSoles";
import Lukova from "./components/work/Lukova";
import Park from "./components/work/Park";
import Pflag from "./components/work/Pflag";
import PlasticTitanic from "./components/work/PlasticTitanic";
import Sculpture from "./components/work/Sculpture";
import Type from "./components/work/Type";
import Contact from "./components/Contact";


function App() {
    const domain = /https:\/\/[^/]+/;
    const basename = process.env.PUBLIC_URL.replace(domain, "");

  return (
    <div className="">
        <BrowserRouter basename={basename} >
            <NavBar />
            <Routes>
                <Route path="" element={<GalleryRedirect />} />
                <Route path="about" element={<About />} />
                <Route path="work" >
                    <Route path="" element={<Gallery />} />
                    <Route path="plastic-titanic" element={<PlasticTitanic />}/>
                    <Route path="type-101" element={<Type />} />
                    <Route path="lukova-brochure" element={<Lukova />} />
                    <Route path="pflag-identity" element={<Pflag />}/>
                    <Route path="park-identity" element={<Park />} />
                    <Route path="lost-soles" element={<LostSoles />} />
                    <Route path="sculpture" element={<Sculpture />} />
                </Route>
                <Route path="contact" element={<Contact />} />
            </Routes>

        </BrowserRouter>
    </div>
  );
}

export default App;
