import PlasticTitanic1 from "../../images/titanic/PlasticTitanic.png"
import T1 from "../../images/titanic/Titanic1.png"
import T2 from "../../images/titanic/Titanic2.jpg"
import T3 from "../../images/titanic/Titanic3.png"
import T4 from "../../images/titanic/Titanic4.png"
import T5 from "../../images/titanic/TitanicProcess1.png"
import T6 from "../../images/titanic/TitanicProcess2.png"
import T7 from "../../images/titanic/TitanicProcess3.png"
import T8 from "../../images/titanic/TitanicProcess4.png"
import T9 from "../../images/titanic/TitanicProcess5.png"
import T10 from "../../images/titanic/TitanicProcess6.png"

function PlasticTitanic() {
    return (
        <>
        <div className="flex max-h-[92vh]">
            <div className="flex flex-col place-content-center bg-jade h-[86vh] w-[33vw] mt-[3vh] mb-[3vh] ml-[3vh] rounded-lg">
                <div className="flex-col pb-2">
                    <p className="flex text-2xl font-bold italic mx-8">Plastic Titanic Poster:</p>
                    <p className="flex text-lg font-bold mx-8">Public service announcement poster design on the <br />effects of climate change.</p>
                </div>
                <p className="text-m px-8 pb-2">To address the issue of the climate crisis this poster relates the danger and tragedy of the Titanic, to the problems of plastics in our oceans. Emphasizing the thought of the captain's belief of the strength and integrity of the ship and comparing that to the hubris of climate change deniers. The implementation of the singular quote from the movie The Titanic ties the concepts together and takes something that references the power of the great ship that inevitably sank, to our world that is falling plague to plastics. The relation of the destruction of something so grand by a seemingly arbitrary object highlights this pressing issue creating a valuable public service announcement poster.</p>
                <p className="flex text-m font-bold italic mx-8">Process:</p>
                <p className="text-m px-8">Various exercises in divergent thinking were employed to explore the topic of climate change. The Cube project shows a life cycle of a water bottle and the positive and negative effects it can have. The color and simple icons highlight what can become of a water bottle. The simple typeface allows the meaning and imagery to be the focal point, and by having the negative word be upside down reinforces that contrast. <br />Four separate images combined to form pragmatic, poetic and persuasive communications, each with the goal of unity. Taking otherwise unsimilar images and putting them into the context of the effects of climate change, putting them together in a way to make formal unity was the focus. Exploring this purely image based way of communication provided a new perspective on how to discuss the effects of climate change.</p>
            </div>
            <div className="flex flex-col place-items-center h-92[vh] w-[67vw] overflow-y-scroll scrollbar-thin scrollbar-thumb-jade scrollbar-rounded-lg">
                <label htmlFor="my-modal-1" className=""><img alt="" src={PlasticTitanic1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[30vw]"/></label>
                <label htmlFor="my-modal-2" className=""><img alt="" src={T1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw]"/></label>
                <div className="flex w-[50vw] gap-x-2 m-8">
                    <label htmlFor="my-modal-3" className=""><img alt="" src={T2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black"/></label>
                    <label htmlFor="my-modal-4" className=""><img alt="" src={T3} className="border-2 border-jade rounded-lg shadow-2xl shadow-black"/></label>
                    <label htmlFor="my-modal-5" className=""><img alt="" src={T4} className="border-2 border-jade rounded-lg shadow-2xl shadow-black"/></label>
                </div>
                <div className="flex w-[50vw] gap-x-2 m-8">
                    <label htmlFor="my-modal-6" className=""><img alt="" src={T5} className="border-2 border-jade rounded-lg shadow-2xl shadow-black"/></label>
                    <label htmlFor="my-modal-7" className=""><img alt="" src={T6} className="border-2 border-jade rounded-lg shadow-2xl shadow-black"/></label>
                    <label htmlFor="my-modal-8" className=""><img alt="" src={T7} className="border-2 border-jade rounded-lg shadow-2xl shadow-black"/></label>
                </div>
                <div className="flex w-[50vw] gap-x-2 m-8">
                    <label htmlFor="my-modal-9" className=""><img alt="" src={T8} className="border-2 border-jade rounded-lg shadow-2xl shadow-black"/></label>
                    <label htmlFor="my-modal-10" className=""><img alt="" src={T9} className="border-2 border-jade rounded-lg shadow-2xl shadow-black"/></label>
                    <label htmlFor="my-modal-11" className=""><img alt="" src={T10} className="border-2 border-jade rounded-lg shadow-2xl shadow-black"/></label>
                </div>

            </div>
        </div>
        <input type="checkbox" id="my-modal-1" className="modal-toggle" />
        <label htmlFor="my-modal-1" className="modal cursor-pointer">
            <div className="modal-box w-11/12 max-h-[59rem] max-w-[41rem]">
                <img alt="" src={PlasticTitanic1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black h-[56rem] w-[38rem]"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-2" className="modal-toggle" />
        <label htmlFor="my-modal-2" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[78rem] max-h-[60rem]">
                <img alt="" src={T1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-3" className="modal-toggle" />
        <label htmlFor="my-modal-3" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[60rem] max-h-[60rem]">
                <img alt="" src={T2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
        <label htmlFor="my-modal-4" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[60rem] max-h-[60rem]">
                <img alt="" src={T3} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
        <label htmlFor="my-modal-5" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[60rem] max-h-[60rem]">
                <img alt="" src={T4} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-6" className="modal-toggle" />
        <label htmlFor="my-modal-6" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[60rem] max-h-[60rem]">
                <img alt="" src={T5} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-7" className="modal-toggle" />
        <label htmlFor="my-modal-7" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[60rem] max-h-[60rem]">
                <img alt="" src={T6} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-8" className="modal-toggle" />
        <label htmlFor="my-modal-8" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[60rem] max-h-[60rem]">
                <img alt="" src={T7} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-9" className="modal-toggle" />
        <label htmlFor="my-modal-9" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[60rem] max-h-[60rem]">
                <img alt="" src={T8} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-10" className="modal-toggle" />
        <label htmlFor="my-modal-10" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[60rem] max-h-[60rem]">
                <img alt="" src={T9} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-11" className="modal-toggle" />
        <label htmlFor="my-modal-11" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[60rem] max-h-[60rem]">
                <img alt="" src={T10} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        </>
    )
}
export default PlasticTitanic;
