import Y1 from "../../images/young/YPLogoFull.png"
import Y2 from "../../images/young/YPLogo1.png"
import Y3 from "../../images/young/YPLogo2.png"

function Park() {
        return (
        <>
        <div className="flex max-h-[92vh]">
            <div className="flex flex-col place-content-center bg-jade h-[86vh] w-[33vw] mt-[3vh] mb-[3vh] ml-[3vh] rounded-lg">
                <div className="flex-col pb-8">
                    <p className="flex text-2xl font-bold italic mx-8">Personal Logo:</p>
                    <p className="flex text-xl font-bold mx-8">Identity Design for Youngsuk Park</p>
                </div>
                <p className="text-xl px-8">The concept for this design was to take the Korean writing (Hangul) with English type to represent the dual Korean-American identity of the client. To create unity, the chosen typeface looks similar to the Hangul. Finding the relationship between part of the Hangul for his last name, and the letter “Y” brought together the two ideas. Flexibility of the logo was important, this was enabled by having a primary as well as an icon for use in different settings.</p>
            </div>
            <div className="flex flex-col place-items-center h-92[vh] w-[67vw] overflow-y-scroll scrollbar-thin scrollbar-thumb-jade scrollbar-rounded-lg">
                <label htmlFor="my-modal-1" className=""><img alt="" src={Y1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw]"/></label>
                <label htmlFor="my-modal-2" className=""><img alt="" src={Y2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[28vw]"/></label>
                <label htmlFor="my-modal-3" className=""><img alt="" src={Y3} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw]"/></label>
            </div>
        </div>
        <input type="checkbox" id="my-modal-1" className="modal-toggle" />
        <label htmlFor="my-modal-1" className="modal cursor-pointer">
            <div className="modal-box w-11/12 max-w-[73rem]">
                <img alt="" src={Y1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black h-[49.1rem] w-[70.3rem]"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-2" className="modal-toggle" />
        <label htmlFor="my-modal-2" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[56.5rem]">
                <img alt="" src={Y2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black h-[52.5rem] w-[53.5rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-3" className="modal-toggle" />
        <label htmlFor="my-modal-3" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[75rem]">
                <img alt="" src={Y3} className="border-2 border-jade rounded-lg shadow-2xl shadow-black h-[48rem] w-[72.1rem] object-cover"/>
            </div>
        </label>
        </>
        )
}
export default Park;
