import SockGif from "../../images/sock/JadeMacVideo2.mp4"
import Sock1 from "../../images/sock/SockLogo.png"
import Sock2 from "../../images/sock/SockWebpage.png"
import Sock3 from "../../images/sock/SockSignIn.png"
import Sock4 from "../../images/sock/SockPost.png"
import Sock5 from "../../images/sock/SockDrawer.png"



function LostSoles() {
    return (
        <>
        <div className="flex max-h-[92vh]">
            <div className="flex flex-col place-content-center bg-jade h-[86vh] w-[33vw] mt-[3vh] mb-[3vh] ml-[3vh] rounded-lg">
                <div className="flex-col pb-12">
                    <p className="flex text-center mx-8 text-2xl font-bold italic">Lost Soles:</p>
                    <p className="flex mx-8 text-xl font-bold">Webpage design for a sock matching website</p>
                </div>
                <p className="align-middle text-xl px-8">This is a collaborative website design made with a group of software engineers. The website is an amusing exploration of a sock finding social media. To invoke the warm and inviting feel similar to a laundry commercial the limited color pallet is softs pastels. The icon references the title by having an outline of a missing sock accompanied by another sock. Choosing “comfortaa” as the typeface fits with the roundness of the sock icons and also reinforces the inviting feel of the design.</p>
            </div>
            <div className="flex flex-col place-items-center h-92[vh] w-[67vw] overflow-y-scroll scrollbar-thin scrollbar-thumb-jade scrollbar-rounded-lg">
                <label htmlFor="my-modal-1" className=""><img alt="" src={Sock1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw] "/></label>
                <label htmlFor="my-modal-4" className=""><video src={SockGif} type="video/mp4" autoPlay muted loop alt="" className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw]  object-cover " /></label>
                <label htmlFor="my-modal-2" className=""><img alt="" src={Sock2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw]  object-cover "/></label>
                <label htmlFor="my-modal-5" className=""><img alt="" src={Sock3} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw]  object-cover "/></label>
                <label htmlFor="my-modal-6" className=""><img alt="" src={Sock4} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw]  object-cover "/></label>
                <label htmlFor="my-modal-7" className=""><img alt="" src={Sock5} className="border-2 border-jade rounded-lg shadow-2xl shadow-black m-8 w-[50vw]  object-cover "/></label>

            </div>
        </div>
        <input type="checkbox" id="my-modal-1" className="modal-toggle" />
        <label htmlFor="my-modal-1" className="modal cursor-pointer">
            <div className="modal-box w-11/12 max-w-[95rem]">
                <img alt="" src={Sock1} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[91.8rem] h-[24.3rem]"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-2" className="modal-toggle" />
        <label htmlFor="my-modal-2" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[95rem] max-h-[58rem]">
                <img alt="" src={Sock2} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[91.8rem] h-[53.1rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
        <label htmlFor="my-modal-4" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[78rem] max-h-[60rem]">
                <video autoPlay muted loop alt="" src={SockGif} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[74.5rem] h-[54.3rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
        <label htmlFor="my-modal-5" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[108rem] max-h-[58rem]">
                <img alt="" src={Sock3} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[105rem] h-[53.1rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-6" className="modal-toggle" />
        <label htmlFor="my-modal-6" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[108rem] max-h-[58rem]">
                <img alt="" src={Sock4} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[105rem] h-[53.1rem] object-cover"/>
            </div>
        </label>
        <input type="checkbox" id="my-modal-7" className="modal-toggle" />
        <label htmlFor="my-modal-7" className="modal cursor-pointer">
            <div className="modal-box relative w-11/12 max-w-[108rem] max-h-[58rem]">
                <img alt="" src={Sock5} className="border-2 border-jade rounded-lg shadow-2xl shadow-black w-[105rem] h-[53.1rem] object-cover"/>
            </div>
        </label>
        </>
    )
}
export default LostSoles;
