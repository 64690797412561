import BoopW from "../images/BoopWhite.png"
import Boop from "../images/Boop.png"
import Jade from "../images/JadePic.png"

function About() {
    return (
        <div className="flex max-h-[92vh]">
            <div className="w-[50vw] flex place-items-center place-content-center relative">
                <img alt="" src={Jade} className="rounded-full h-[65vh]" />
                <img alt="" src={Boop} className="absolute h-[7vh] top-[1vh] left-[1vw]" />
                <img alt="" src={Boop} className="absolute h-[7vh] top-[12vh] left-[7.5vw]" />
                <img alt="" src={Boop} className="absolute h-[7vh] top-[8vh] left-[40vw]" />
                <img alt="" src={Boop} className="absolute h-[7vh] top-[75vh] left-[37vw]" />
                <img alt="" src={Boop} className="absolute h-[7vh] top-[68vh] left-[5vw]" />
                <img alt="" src={Boop} className="absolute h-[7vh] top-[80vh] left-[45vw]" />
                <img alt="" src={Boop} className="absolute h-[7vh] top-[85vh] left-[14vw]" />
                <img alt="" src={Boop} className="absolute h-[7vh] top-[45vh] left-[43vw]" />
                <img alt="" src={Boop} className="absolute h-[7vh] top-[32vh] left-[3.5vw]" />
                <img alt="" src={Boop} className="absolute h-[7vh] top-[0vh] left-[45vw]" />
                <img alt="" src={Boop} className="absolute h-[7vh] bottom-[1vh] left-[1vw]" />
            </div>
            <div className="flex flex-col place-content-center bg-jade h-[86vh] w-[50vw] mt-[3vh] mb-[3vh] mr-[3vh]  rounded-lg">
                <div className="flex-col p-10">
                    <div className="flex justify-center place-items-center">
                        <p className="flex justify-center align-middle text-center text-3xl font-bold italic pb-4">Jade Love Bott</p>
                        <img alt="" src={BoopW} className="h-[3rem] mb-[1.5rem]"/>
                    </div>
                    <p className="flex justify-center text-center text-2xl font-bold italic pb-4">Design Statement:</p>
                    <p className="flex justify-center text-center text-xl font-bold p-12">Creative unity is how I would describe design. Design is focused around trying to find ways in which to link things together, and I have a strong tendency to find a way to organize things for a specific function. By using various problem solving skills I look for simple, but creative solutions for situations. Putting myself into the shoes of the intended audience I can create thoughtful designs that keep the viewer and client in mind. With a background in different fine arts I implement those skills and ideas to open more doors for creativity. I believe that there are so many different kinds of ways to attack a creative problem and I love to see how different angles and perspectives can make and change a design. When given a structure I can see various avenues for solutions that can lead to interesting forms of communication, always searching for a fun way to express ideas. Creativity and the artistic process is something that I am passionate about and am always excited to explore, constantly learning and growing to become the best artist that I can be.</p>
                </div>
            </div>
        </div>

        )
}
export default About;
